import React, { useState } from 'react'
import PropTypes from 'prop-types'

import combineClass from '@helpers/combineClass'

import SearchField from './SearchField'
import SearchResults from './SearchResults'

const SearchBar = ({
  results = [],
  showResults = false,
  onClear = () => {},
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOpen = () => {
    setIsExpanded(true)
  }

  const handleClear = () => {
    setIsExpanded(false)
    onClear()
  }

  return (
    <div className={combineClass('search-bar', isExpanded ? '-absolute' : '')}>
      <div className='wrapper'>
        <SearchField
          {...props}
          onClear={handleClear}
          onOpen={handleOpen}
          isExpanded={isExpanded}
        />
        {showResults && (
          <SearchResults results={results} />
        )}
      </div>
    </div>
  )
}

SearchBar.propTypes = {
  value: PropTypes.string,
  results: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export default SearchBar
