import { getDay, format, isToday, isPast, isFuture, isSameDay } from 'date-fns'

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const TIME_OPTIONS = {
  timeZoneName: 'short'
}

const getDayName = (date) => {
  return DAYS[getDay(date)]
}

const getDateRange = (startDate, endDate, { dateFormat, singleDateFormat = 'MMMM d, yyyy', multiDateFormat = 'MMM d, yyyy' } = {}) => {
  if (isSameDay(startDate, endDate)) {
    return format(startDate, dateFormat || singleDateFormat)
  } else {
    return `${format(startDate, dateFormat || multiDateFormat)} - ${format(endDate, dateFormat || multiDateFormat)}`
  }
}

const getTZAbbr = (date) => {
  return Intl.DateTimeFormat('default', TIME_OPTIONS).format(date).split(' ').slice(-1)
}

const getTimeWithZone = (date) => {
  return format(date, 'h:mm b ') + getTZAbbr(date)
}

const isDateRangeToday = (startDate, endDate) => {
  return (isToday(startDate) || isPast(startDate)) && (isToday(endDate) || isFuture(endDate))
}

export {
  getDayName,
  getDateRange,
  getTimeWithZone,
  getTZAbbr,
  isDateRangeToday
}
