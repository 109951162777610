import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import axios from '@clients/axios'

import Loader, { useLoader } from '@components/Loader'
import EventInfo from '@components/EventInfo'
import Button from '@components/Button'
import TextField from '@components/TextField'

import { ApplicationFormSchema } from './schemas/ApplicationFormSchema'

const EventApplicationForm = ({
  id,
  event,
  values = {},
  onSubmitSuccess,
  disabled = false
}) => {
  const { url, avatarUrl, name, startsAt, endsAt, location } = event
  const { isLoading, startLoading, stopLoading } = useLoader()
  const [loaderType, setLoaderType] = useState('overlay')
  const [customFields, setCustomFields] = useState([])

  const transformCustomFields = (data) => {
    return data.map((field) => {
      return {
        ...field.attributes
      }
    })
  }

  const submitForm = async (formValues) => {
    try {
      const response = await axios.put(`/event_invitations/${id}/submit`, {
        data: formValues
      })
      const { data } = response

      stopLoading()
      onSubmitSuccess('applied', 'Applied to the show successfully')
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  const handleSubmit = (formValues) => {
    if (isLoading) return

    startLoading()
    submitForm(formValues)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: customFields.reduce((acc, field) => {
      return {
        ...acc,
        [field.name]: values[field.name] || field.playerDefaultValue || ''
      }
    }, {}),
    validationSchema: ApplicationFormSchema(customFields),
    onSubmit: (formValues) => {
      !disabled && handleSubmit(formValues)
    }
  })

  const fetchCustomFields = async () => {
    startLoading()
    setLoaderType('spinner')
    try {
      const response = await fetch(`/events/${event.id}/custom_fields`)
      const { data } = await response.json()

      stopLoading()
      setLoaderType('overlay')
      setCustomFields(transformCustomFields(data))
    } catch (error) {
      stopLoading()
      setLoaderType('overlay')
      console.error(error)
    }
  }

  useEffect(() => {
    fetchCustomFields()
  }, [])

  return (
    <>
      {event && (
        <EventInfo
          url={url}
          avatarUrl={avatarUrl}
          name={name}
          startsAt={startsAt}
          endsAt={endsAt}
          location={location}
          extraClass='mb-6'
        />
      )}
      <Loader isLoading={isLoading} type={loaderType}>
        <form onSubmit={formik.handleSubmit}>
          {customFields.map(
            ({ name = '', label = '', helpText = '', position = -1, validationRegex = undefined, validationRegexExample = undefined }) => (
              <TextField
                label={label}
                onChange={formik.handleChange}
                key={position}
                helpText={helpText}
                name={name}
                value={formik.values[name] || ''}
                disabled={disabled}
                inputClass={
                  formik.errors[name] && formik.touched[name] ? 'error' : ''
                }
                validationInfo={formik.touched[name] && formik.errors[name]}
                pattern={validationRegex}
                title={validationRegexExample}
              />
            )
          )}
          {!disabled && (
            <div className='mt-4 flex-elem -end'>
              <Button type='submit' variant='primary'>
                Submit
              </Button>
            </div>
          )}
        </form>
      </Loader>
    </>
  )
}

EventApplicationForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  event: PropTypes.object
}

export default EventApplicationForm
