import React, { useState, useEffect, useMemo } from 'react'

import {
  SectionBlock,
  SectionContent,
  SectionHeading
} from '@components/SectionBlock'
import { Markdown } from '@components/Markdown'

const EventSection = ({ sections = [] }) => {
  return (
    <div className='event-overview-layout'>
      <div className='about'>
        { sections.map((section, index) => (
          <SectionBlock extraClass={index < sections.length ? 'mb-10' : ''}>
            <SectionHeading variant='main'>
              <p className="head">{section.attributes.title}</p>
            </SectionHeading>
            <SectionContent>
              <Markdown text={section.attributes.content} />
            </SectionContent>
          </SectionBlock>
        ))}
      </div>
    </div>
  )
}

export default EventSection
