import React, { useEffect, useMemo, useState } from 'react'
import { parseJSON } from 'date-fns'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import { NavLayout } from '@components/NavLayout'
import { Overview, Bracket, EventSection, Results } from '@pages'

const EventShowContainer = ({
  expressInterestState = "hidden",
  renderPlayerSpotlight = false,
  hasFinalResults = false,
  eventObject,
  tournamentObjects
}) => {
  const [sections, setSections] = useState({})

  const tournaments = useMemo(() => {
    if (tournamentObjects) {
      return tournamentObjects.map(({ id, attributes }) => {
        const startsAtStr = attributes.timestamps?.startsAt
        const startsAt = startsAtStr ? parseJSON(startsAtStr) : null

        return {
          id,
          startsAt,
          ...attributes
        }
      })
    } else {
      return []
    }
  }, [tournamentObjects])

  const event = useMemo(() => {
    const { id, attributes } = eventObject
    const startsAtStr = attributes.startsAt
    const startsAt = startsAtStr ? parseJSON(startsAtStr) : null

    return {
      id,
      startsAt,
      tournaments: tournaments,
      ...attributes
    }
  }, [eventObject])

  const isNotEmpty = (arr) => {
    return Array.isArray(arr) && arr.length > 0
  }

  const shownSections = useMemo(() => {
    return Object.keys(sections).reduce((acc, key) => {
      return isNotEmpty(sections[key]) ? [...acc, key] : acc
    }, [])
  }, [sections])

  // Change to useSWR
  useEffect(() => {
    fetch(`/events/${event.slug}/sections.json`)
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }

        throw new Error('Network Error')
      })
      .then((data) => {
        const groupedSections = data.reduce((acc, section) => {
          const type = section.attributes.type

          if (isNotEmpty(acc[type])) {
            acc[type] = [...acc[type], section]
          } else {
            acc[type] = [section]
          }

          return acc
        }, {})

        setSections(groupedSections)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <Router>
      <div className='event-show-container'>
        <Routes>
          <Route
            path='/events'
            element={<NavLayout event={event} expressInterestState={expressInterestState} sections={shownSections} hasFinalResults={hasFinalResults} />}
          >
            <Route
              path={hasFinalResults ? ':slug/overview' : ':slug'}
              element={<Overview event={event} renderPlayerSpotlight={renderPlayerSpotlight} />}
            />
            <Route
              path=':slug/bracket'
              element={<Bracket event={event} />}
            />
            { hasFinalResults &&
              <Route
                path=':slug'
                element={<Results event={event} />}
              />
            }
            { shownSections.includes('private_player_section') &&
              <Route
                path=':slug/private_player_section'
                element={<EventSection sections={sections['private_player_section']} />}
              />
            }
            { shownSections.includes('rules') &&
              <Route
                path=':slug/rules'
                element={<EventSection sections={sections['rules']} />}
              />
            }
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default EventShowContainer
