import React from 'react'
import combineClass from '@helpers/combineClass'

const Spinner = ({ extraClass='-padded' }) => {
  return (
    <div className={combineClass('spinner-icon -centered', extraClass)}>
      <div className='icon' />
    </div>
  )
}

export default Spinner
