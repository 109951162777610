import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import combineClass from '@helpers/combineClass'

import CloseIcon from '@images/icons/close-icon.svg'

const Modal = ({
  isOpen,
  onClose,
  children,
  notClosable = false,
  customClass = '',
  contentClass = ''
}) => {
  const portalNode = document.getElementById('react-portal-modal')
  const modalContainerRef = useRef(null)
  const displayClass = isOpen ? 'block' : 'none'
  const animateClass = isOpen ? 'animate-grow' : 'animate-shrink'

  const handleEsc = (e) => {
    if (e.keyCode === 27) {
      onClose()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].classList.add('modal-open')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }
  }, [isOpen])

  useEffect(() => {
    if (!notClosable) {
      document.addEventListener('keydown', handleEsc)
    }

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [])

  useEffect(() => {
    const handleContainerClick = (e) => {
      if (modalContainerRef.current === e.target) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleContainerClick)
    return () => {
      document.removeEventListener('mousedown', handleContainerClick)
    }
  }, [modalContainerRef])

  if (!isOpen) return null
  return ReactDOM.createPortal(
    <>
      <div
        className='modal'
        style={{ display: displayClass }}
        ref={modalContainerRef}
      >
        <div
          className={combineClass(
            'modal-dialog',
            animateClass,
            customClass
          )}
        >
          <div className={combineClass('modal-content', contentClass)}>
            {!notClosable && (
              <button className='close' type='button' onClick={onClose}>
                <svg className='icon react-svg-icon -white'>
                  <use href={CloseIcon + `#icon-close`} />
                </svg>
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
      <div className='modal-overlay'></div>
    </>,
    portalNode
  )
}

export default Modal
