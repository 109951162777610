import React from 'react'
import { render } from 'react-dom'
import { parseJSON } from 'date-fns'
import { getTimeWithZone, getDateRange } from '@helpers/datetime-helper'

import EventListContainer from '@containers/EventListContainer'
import EventShowContainer from '@containers/EventShowContainer'
import DashboardContainer from '@containers/DashboardContainer'
import UserSettings from '@components/UserSettings'
import UserSearch from '@components/UserSearch'
import UserProfile from '@components/UserProfile'

const renderEventList = () => {
  const elem = document.getElementById('twitch-rivals-event-list')

  if (elem !== null) {
    render(<EventListContainer />, elem)
  }
}

const renderEventShow = () => {
  const elem = document.getElementById('twitch-rivals-event-show')

  if (elem !== null) {
    const eventObject = JSON.parse(elem.dataset.event)
    const tournamentObjects = JSON.parse(elem.dataset.tournaments)
    const expressInterestState = elem.dataset.expressInterestState
    const hasFinalResults = elem.hasAttribute('data-has-final-results')
    const renderPlayerSpotlight = elem.hasAttribute('data-render-player-spotlight')

    render(
      <EventShowContainer
        eventObject={eventObject}
        tournamentObjects={tournamentObjects}
        expressInterestState={expressInterestState}
        renderPlayerSpotlight={renderPlayerSpotlight}
        hasFinalResults={hasFinalResults}
      />,
      elem
    )
  }
}

const renderDate = () => {
  const elem = document.getElementById('formatted-date')

  if (elem !== null) {
    const startsAt = parseJSON(elem.dataset.startsAt)
    const endsAt = parseJSON(elem.dataset.endsAt)

    render(getDateRange(startsAt, endsAt), elem)
  }
}

const renderTime = () => {
  const elem = document.getElementById('formatted-time-with-zone')

  if (elem !== null) {
    const date = parseJSON(elem.dataset.date)

    render(getTimeWithZone(date), elem)
  }
}

const renderDashboard = () => {
  const elem = document.getElementById('dashboard')

  if (elem !== null) {
    const username = elem.dataset.username
    const displayName = elem.dataset.displayName
    const twitchAvatar = elem.dataset.profileImage

    render(
      <DashboardContainer
        username={username}
        displayName={displayName}
        twitchAvatar={twitchAvatar}
      />,
      elem
    )
  }
}

const renderUserSettings = () => {
  const elem = document.getElementById('user-settings')

  if (elem !== null) {
    render(
      <UserSettings />,
      elem
    )
  }
}

const renderUserSearch = () => {
  const elem = document.getElementById('user-search')

  if (elem !== null) {
    render(
      <UserSearch />,
      elem
    )
  }
}

const renderUserProfile = () => {
  const elem = document.getElementById('user-profile')

  if (elem !== null) {
    const userObj = JSON.parse(elem.dataset.user)
    const user = {
      ...userObj.data.attributes,
      links: userObj.data.links
    }
    const events = JSON.parse(elem.dataset.events)

    render(
      <UserProfile user={user} events={events} />,
      elem
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  renderEventList()
  renderEventShow()
  renderDate()
  renderTime()
  renderDashboard()
  renderUserSettings()
  renderUserSearch()
  renderUserProfile()
})
