import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import combineClass from '@helpers/combineClass'

const Tab = ({ children }) => <>{children}</>

const NavTabs = ({
  children,
  initialTab = 0,
  onChange = () => {},
  tabType = ''
}) => {
  const tabTypeClass = useMemo(() => {
    switch (tabType) {
      case 'button':
        return '-btn'
      case 'wide':
        return '-wide'
      default:
        return ''
    }
  }, [tabType])

  const [tabIndex, setTabIndex] = useState(initialTab)

  const handleTab = (idx) => {
    setTabIndex(idx)
    onChange(idx)
  }

  const tabItems = useMemo(() => {
    return children.map((child, idx) => (
      <div
        key={child.props.label}
        className={combineClass(
          'tab',
          tabTypeClass,
          tabIndex === idx ? '-active' : ''
        )}
        onClick={() => handleTab(idx)}
      >
        {child.props.label}
      </div>
    ))
  }, [tabIndex])

  const tabContent = useMemo(() => {
    return children.filter((_, idx) => idx === tabIndex)
  }, [tabIndex])

  return (
    <div className='nav-tabs'>
      <div className='tab-groups'>{tabItems}</div>
      <div className='tab-content'>{tabContent}</div>
    </div>
  )
}

NavTabs.propTypes = {
  initialTab: PropTypes.number,
  tabType: PropTypes.oneOf(['', 'button', 'wide'])
}

export { Tab }
export default NavTabs
