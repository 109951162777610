import React, { useState, useEffect } from 'react'
import Loader, { useLoader } from '@components/Loader'

import {
  SectionBlock,
  SectionHeading,
  SectionContent
} from '@components/SectionBlock'
import TwitchUserCard from '../components/TwitchUserCard/TwitchUserCard'

import GoldMedal from '@images/icons/icon-medal-first.svg'
import SilverMedal from '@images/icons/icon-medal-second.svg'
import BronzeMedal from '@images/icons/icon-medal-third.svg'

const Results = ({ event }) => {
  const { isLoading, startLoading, stopLoading } = useLoader()
  const [results, setResults] = useState([])

  useEffect(() => {
    startLoading()

    fetch(`/events/${event.slug}/overall_results.json`, {method: "get"})
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }

        throw new Error('Network Error')
      })
      .then((data) => {
        setResults(data)
        stopLoading()
      })
      .catch((error) => {
        console.log(error)
        stopLoading()
      })
  }, [])

  return (
    <SectionBlock extraClass='mb-4'>
      <SectionHeading variant='side' color='white'>
        <p className="head">Overall Results</p>
      </SectionHeading>
      <SectionContent>
        <Loader isLoading={isLoading}>
          {results.length > 0 ? (
            <>
              <div style={{display: "grid", marginBottom: "100px", gap: "40px 20px", gridTemplateColumns: "repeat(auto-fit, minmax(300px, max-content))"}}>
                {results.slice(0, 3).map((result, idx) => {
                  let medal = GoldMedal

                  if (idx === 1) {
                    medal = SilverMedal
                  } else if (idx === 2) {
                    medal = BronzeMedal
                  }

                  return (
                    <div key={result.team}>
                      <div style={{position: "absolute"}}>
                        <div style={{position: "relative", top: "1px", left: "6px"}}>
                          <img src={medal} height="46" />
                        </div>
                      </div>
                      <div style={{color: "#000000", fontSize: "16px", fontWeight: "700", backgroundColor: "#9146FF", padding: "16px 16px 16px 59px"}}>
                        {result.team}
                      </div>
                      <div style={{backgroundColor: "#180E25", paddingTop: "5px"}}>
                        {result.players.map((player) => (
                          <TwitchUserCard
                            displayName={player.display_name}
                            profileImageUrl={player.profile_image_url}
                            compact={true}
                          />
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div style={{display: "grid", gap: "40px 20px", gridTemplateColumns: "repeat(auto-fit, minmax(300px, max-content))"}}>
                {results.slice(3).map((result) => (
                  <div key={result.team}>
                    <div style={{color: "#000000", fontSize: "16px", fontWeight: "700", backgroundColor: "#9146FF", padding: "16px"}}>
                      {`${result.placement}. ${result.team}`}
                    </div>
                    <div style={{backgroundColor: "#180E25", paddingTop: "5px"}}>
                      {result.players.map((player) => (
                        <TwitchUserCard
                          displayName={player.display_name}
                          profileImageUrl={player.profile_image_url}
                          compact={true}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>Final results are not yet available.<br /><br /></div>
          )}
        </Loader>
      </SectionContent>
    </SectionBlock>
  )
}

export default Results
