import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { isPast, isWithinInterval } from 'date-fns'

import EventInfo from '@components/EventInfo'
import Button from '@components/Button'
import combineClass from '@helpers/combineClass'
import GoldMedal from '@images/icons/icon-medal-first.svg'
import SilverMedal from '@images/icons/icon-medal-second.svg'
import BronzeMedal from '@images/icons/icon-medal-third.svg'

const UserEventBlock = ({
  name,
  startsAt,
  endsAt,
  avatarUrl,
  bannerUrl,
  location,
  url,
  placement,
  extraClass = ''
}) => {
  const handleWatchNowClick = useCallback(() => {
    window.open('https://twitch.tv/twitchrivals')
  }, [])

  const isLive = isWithinInterval(new Date(), { start: startsAt, end: endsAt })

  return (
    <div className={combineClass('event-block', extraClass)}>
      <EventInfo
        url={url}
        avatarUrl={avatarUrl}
        name={name}
        startsAt={startsAt}
        endsAt={endsAt}
        location={location}
        extraClass="-compact"
      >
        {isLive && (
          <span className='tag -live'>
            <strong>LIVE</strong>
          </span>
        )}
      </EventInfo>
      <div className='actions'>
        {isLive && (
          <Button variant='secondary' onClick={handleWatchNowClick}>
            Watch Now
          </Button>
        )}
      </div>
      {isLive && <img src={bannerUrl} alt='banner' className='banner' />}
      {placement && (
        <div className='placement'>
          {placement === '1st' && <img src={GoldMedal} height="72" />}
          {placement === '2nd' && <img src={SilverMedal} height="72" />}
          {placement === '3rd' && <img src={BronzeMedal} height="72" />}
          {placement !== '1st' && placement !== '2nd' && placement !== '3rd' && (
            <>{placement} Place</>
          )}
        </div>
      )}
    </div>
  )
}

UserEventBlock.propTypes = {
  name: PropTypes.string.isRequired,
  startsAt: PropTypes.instanceOf(Date),
  endsAt: PropTypes.instanceOf(Date),
  avatarUrl: PropTypes.string,
  bannerUrl: PropTypes.string,
  location: PropTypes.string,
  url: PropTypes.string,
  placement: PropTypes.number
}

export default UserEventBlock
