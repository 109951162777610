import React, { useState } from 'react'
import axios from '@clients/axios'

import { useLoader } from '@components/Loader'
import FlashMessage, { useFlashMessage } from '@components/FlashMessage'
import Button from '@components/Button'

const ExpressInterestButton = ({expressInterestState = 'hidden', eventSlug = '', disabled = false}) => {
  const [isDisabled, setIsDisabled] = useState(disabled)
  const { isLoading, startLoading, stopLoading } = useLoader()
  const {
    flashMessageState,
    openFlashMessage,
    closeFlashMessage
  } = useFlashMessage()

  const handleClick = () => {
    startLoading()
    postRequest()
  }

  const postRequest = async () => {
    try {
      const response = await axios.post(`/events/${eventSlug}/express_interest`)
      const { data } = response

      openFlashMessage('success', 'Successfully expressed interest. We’ll reach out if you’re a good fit.')
      stopLoading()
      setIsDisabled(true)
    } catch (error) {
      stopLoading()
      openFlashMessage('error', 'Something went wrong.')
      console.error(error)
    }
  }

  const invitationPending = (
    expressInterestState === "participant-invited" ||
    expressInterestState === "participant-applied" ||
    expressInterestState === "participant-accepted"
  )

  return (
    <>
      {expressInterestState !== "hidden" && !invitationPending && (
        (expressInterestState === "signed-out") ? (
          <a
            className="btn btn-primary"
            rel="nofollow"
            data-method="post"
            href="/users/auth/twitch"
          >
            Sign in to express interest
          </a>
         ) : (
          <>
            <Button
              onClick={handleClick}
              disabled={isLoading || isDisabled || (expressInterestState !== "eligible" && expressInterestState !== "signed-out")}
              variant='primary'
            >
              { isLoading ? (
                'Loading...'
              ) : (isDisabled || expressInterestState === "expressed") ? (
                'Expressed Interest'
              ) : (expressInterestState === "participant-confirmed") ? (
                "You're a Confirmed Participant"
              ) : (expressInterestState === "eligible") ? (
                'Express Interest'
              ) : 'Ineligible'}
            </Button>
            
            <FlashMessage {...flashMessageState} onClose={closeFlashMessage} />
          </>
        )
      )}
      {invitationPending && (
        <a className="btn btn-primary" href="/dashboard">View your invitation</a>
      )}
    </>
  )
}

export default ExpressInterestButton
