const flashMessageReducer = (state, { type, payload }) => {
  switch (type) {
    case 'OPEN':
      return { ...state, isOpen: true, ...payload }
    case 'CLOSE':
      return { isOpen: false, status: '', message: '' }
  }
}

export default flashMessageReducer
