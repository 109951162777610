import React, { useEffect, useState, useCallback } from 'react'
import { parseJSON } from 'date-fns'
import axios from '@clients/axios'

import {
  SectionBlock,
  SectionHeading,
  SectionContent
} from '@components/SectionBlock'
import Loader, { useLoader } from '@components/Loader'
import { ProfileBanner } from '@components/ProfileBanner'
import FlashMessage, { useFlashMessage } from '@components/FlashMessage'
import { FilterableEventInvitationList } from '@components/FilterableEventInvitationList'
import CoStreaming from '@components/CoStreaming'

const DashboardContainer = ({ username, displayName, twitchAvatar }) => {
  const {
    flashMessageState,
    openFlashMessage,
    closeFlashMessage
  } = useFlashMessage()
  const [activeTab, setActiveTab] = useState('all')
  const [eventInvitations, setEventInvitations] = useState([])
  const { isLoading, startLoading, stopLoading } = useLoader()
  const [showCostreaming, setShowCostreaming] = useState()

  const transformData = ({ data, included }) => {
    return data.map((obj, index) => {
      const eventId = obj.relationships.event.data.id
      const eventData = included.find(resource => resource.type === "event" && resource.id === eventId)

      return {
        ...obj.attributes,
        event: transformEventData(eventData)
      }
    })
  }

  const transformEventData = ({ id, attributes }) => {
    const startsAt = parseJSON(attributes.startsAt)
    const endsAt = parseJSON(attributes.endsAt)

    return {
      ...attributes,
      id,
      startsAt,
      endsAt,
      url: window.location.origin + '/events/' + attributes.slug
    }
  }

  const fetchData = async (tab = 'all') => {
    try {
      const response = await axios.get('/event_invitations', {
        params: { status: tab }
      })
      const { data } = response

      stopLoading()
      setEventInvitations(transformData(data))
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  const fetchShowCostreaming = async () => {
    try {
      const response = await axios.get('events/check_presence')
      const { data } = response
      stopLoading()
      setShowCostreaming(data.has_event)
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  const handleFormSubmitSuccess = useCallback((tab = '', message = '') => {
    setActiveTab(tab)
    openFlashMessage('success', message)
  }, [])

  useEffect(() => {
    if (isLoading) return

    startLoading()
    fetchShowCostreaming()
  }, [])

  useEffect(() => {
    if (isLoading) return

    startLoading()
    fetchData(activeTab)
  }, [activeTab])

  return (
    <>
      <ProfileBanner
        username={username}
        displayName={displayName}
        twitchAvatar={twitchAvatar}
      />
      <div className='layout-container'>
        <Loader isLoading={isLoading} type='overlay'>
          { showCostreaming && (
            <SectionBlock>
              <SectionHeading variant='main' color='white' extraClass='mb-7'>
                <p className='head'>Your Events</p>
                <p className='sub'>
                  Accept a show invitation by clicking{' '}
                  <strong className='chl-text'>
                    ACCEPT INVITATION
                  </strong>{' '}
                  in the event details.
                </p>
              </SectionHeading>
              <SectionContent>
                <FilterableEventInvitationList
                  activeTab={activeTab}
                  eventInvitations={eventInvitations}
                  onTabChange={setActiveTab}
                  onFormSubmitSuccess={handleFormSubmitSuccess}
                />
              </SectionContent>
            </SectionBlock>
          )}
        </Loader>
      </div>
      <FlashMessage {...flashMessageState} onClose={closeFlashMessage} />
    </>
  )
}

export default DashboardContainer
