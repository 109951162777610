const titleize = (string) => {
  const stringArr = string.split(/(\s+)/)

  return stringArr.reduce((acc, str) => {
    const [firstLetter, ...rest] = str.toLowerCase()

    return [...acc, firstLetter.toUpperCase(), ...rest].join('')
  }, '')
}

const camelize = (str) => {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function (b) {
      return b.toLowerCase()
    })
}

export { titleize, camelize }
