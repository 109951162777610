// copypasta from this link https://www.measurethat.net/Benchmarks/Show/8900/0/classnames-vs-clsx-vs-alternatives-vs-keys
const ts = (...args) => {
  let template = ''
  args.forEach((arg) => {
    if (typeof arg === 'string') {
      template += ' ' + arg
    } else if (arg.join) {
      arg.forEach((e) => {
        template += ' ' + e
      })
    } else {
      const entries = Object.entries(arg)
      if (entries.length) {
        entries.forEach((entry) => {
          if (entry[1]) template += ' ' + entry[0]
        })
      }
    }
  })
  return template
}

export default ts
