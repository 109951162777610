import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import combineClass from '@helpers/combineClass'

const Grid = ({ column, className = '', children }) => {
  const columnClass = useMemo(() => {
    switch (column) {
      case 2:
        return '-two'
      case 3:
        return '-three'
      default:
        return ''
    }
  }, [column])

  return (
    <div className={combineClass('grid', className, columnClass)}>
      {children}
    </div>
  )
}

Grid.propTypes = {
  className: PropTypes.string,
  column: PropTypes.number
}

export default Grid
