import React, { useMemo } from 'react'
import { isFuture } from 'date-fns'
import { isDateRangeToday } from '@helpers/datetime-helper'

import { NavTabs, Tab } from '@components/NavTabs'
import { EventList } from '@components/EventList'
import { BlankSlate } from '@components/BlankSlate'

const FilterableEventList = ({events, isLoading}) => {
  const [upcomingEvents, pastEvents] = useMemo(() => {
    return events.reduce((acc, event) => {
      if (isDateRangeToday(event.startsAt, event.endsAt) || isFuture(event.startsAt)) {
        return [
          [...acc[0], event],
          acc[1]
        ]
      } else {
        return [
          acc[0],
          [...acc[1], event],
        ]
      }
    }, [[], []])
  }, [events])

  const upcomingBlankSlateText = isLoading ? "Looking up shows" : 'No upcoming tournaments available.'
  const pastBlankSlateText = isLoading ? "Looking up shows" : 'No past tournaments available.'

  return (
    <div className='event-list-container'>
      <NavTabs initialTab={0}>
        <Tab label='Upcoming'>
          { upcomingEvents.length > 0 ? (
            <EventList events={upcomingEvents} sortDir='asc'/>
          ) : (
            <BlankSlate text={upcomingBlankSlateText} />
          )}
        </Tab>
        <Tab label='Past'>
          { pastEvents.length > 0 ? (
            <EventList events={pastEvents} sortDir='desc'/>
          ) : (
            <BlankSlate text={pastBlankSlateText} />
          )}
        </Tab>
      </NavTabs>
    </div>
  )
}

export default FilterableEventList
