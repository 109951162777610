import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'

import axios from '@clients/axios'
import EventInfo from '@components/EventInfo'
import Button from '@components/Button'
import Checkbox from '@components/Checkbox'
import Loader, { useLoader } from '@components/Loader'

import TermsConditionFormSchema from './schemas/TermsConditionsFormSchema'

const EventTermsConditionsForm = ({
  id,
  event,
  termsAndConditionsUrl,
  onSubmitSuccess
}) => {
  const { isLoading, startLoading, stopLoading } = useLoader()
  const { url, avatarUrl, name, startsAt, endsAt, location } = event

  const handleSubmit = async (formValues) => {
    startLoading()

    try {
      const response = await axios.put(`/event_invitations/${id}/confirm`, {
        data: formValues
      })
      const { data } = response

      stopLoading()
      onSubmitSuccess('confirmed', `You're successfully in the show`)
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      acceptedTerms: false
    },
    validationSchema: TermsConditionFormSchema,
    onSubmit: handleSubmit
  })

  const handleAcceptTermsClick = () => {
    formik.setFieldValue('acceptedTerms', !formik.values.acceptedTerms)
  }

  return (
    <>
      {event && (
        <EventInfo
          url={url}
          avatarUrl={avatarUrl}
          name={name}
          startsAt={startsAt}
          endsAt={endsAt}
          location={location}
          extraClass='mb-6'
        />
      )}
      <Loader isLoading={isLoading} type='overlay'>
        <form onSubmit={formik.handleSubmit}>
          <div className='vertical-field'>
            <Checkbox
              value={formik.values.acceptedTerms}
              onClick={handleAcceptTermsClick}
            >
              <span className='text'>
                I agree to this Event's &nbsp;
                <a
                  className='link -purple -underline'
                  href={termsAndConditionsUrl}
                  target='_blank'
                  rel='noreferrer'
                >
                  Terms and Conditions
                </a>
                .
              </span>
            </Checkbox>
            {formik.touched.acceptedTerms && formik.errors.acceptedTerms && (
              <p className='validation -error'>{formik.errors.acceptedTerms}</p>
            )}
          </div>
          <div className='mt-4 flex-elem -end'>
            <Button type='submit' variant='primary'>
              Confirm Terms and Conditions
            </Button>
          </div>
        </form>
      </Loader>
    </>
  )
}

EventTermsConditionsForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  event: PropTypes.object
}

export default EventTermsConditionsForm
