import React from 'react'
import PropTypes from 'prop-types'

import combineClass from '@helpers/combineClass'

const AnchorLink = ({ children, href, className = '' }) => (
  <a target='_blank' className={combineClass('link', className)} href={href}>
    {children}
  </a>
)

export default AnchorLink

AnchorLink.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string
}
