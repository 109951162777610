import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import combineClass from '../../helpers/combineClass'

const SectionHeading = ({
  variant = '',
  color = '',
  extraClass = '',
  children
}) => {
  const variantClass = useMemo(() => {
    switch (variant) {
      case 'main':
      case 'side':
        return `-${variant}`
      default:
        return ''
    }
  }, [variant])

  const colorClass = useMemo(() => {
    switch (color) {
      case 'purple':
      case 'white':
        return `-${color}`
      default:
        return ''
    }
  }, [color])

  return (
    <div className={combineClass('heading', variantClass, colorClass, extraClass)}>
      {children}
    </div>
  )
}

SectionHeading.propTypes = {
  variant: PropTypes.oneOf(['main', 'side']),
  color: PropTypes.oneOf(['white', 'purple']),
  extraClass: PropTypes.string
}

export default SectionHeading
