import React from 'react'
import PropTypes from 'prop-types'
import TwitchLogo from '@images/logos/logo-twitch.svg'

const BlankSlate = ({ text }) => {
  return (
    <div className='blank-slate'>
      <div className='content'>
        <img className='mb-3' src={TwitchLogo} alt='Twitch Logo' />
        <strong>{text}</strong>
      </div>
    </div>
  )
}

BlankSlate.propTypes = {
  text: PropTypes.string
}

export default BlankSlate
