import { useReducer } from 'react'
import modalReducer from '../reducers/modalReducer'

const useModal = () => {
  const [state, dispatch] = useReducer(modalReducer, { isOpen: false })

  const closeModal = () => {
    dispatch({ type: 'CLOSE' })
  }

  const openModal = (Component, modalProps = {}) => {
    dispatch({ type: 'OPEN', payload: { children: Component, ...modalProps } })
  }

  return {
    openModal,
    closeModal,
    modalState: state
  }
}

export default useModal
