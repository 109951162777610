import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import combineClass from '@helpers/combineClass'

const CustomLink = ({ to, children }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link className={combineClass('tab -wide', match ? '-active' : '')} to={to}>
      {children}
    </Link>
  )
}

export default CustomLink
