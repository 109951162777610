import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'
import { useSearchParams, useParams, useNavigate, useLocation } from 'react-router-dom'
import { sortBy } from 'lodash'

import SimpleTabs, {
  TabGroup,
  TabContent,
  Tab,
  TabPane
} from '@components/SimpleTabs'
import {
  SectionBlock,
  SectionHeading,
  SectionContent
} from '@components/SectionBlock'

import { Markdown } from '@components/Markdown'
import { Collapse } from '@components/Collapse'

const MIN_IFRAME_HEIGHT = 600

const Bracket = ({ event }) => {
  const { tournaments = [] } = event
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState('')
  const { slug } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const [iframeHeight, setIframeHeight] = useState(MIN_IFRAME_HEIGHT)
  const iframeRef = useRef(null)

  const handleTabChange = (value) => {
    setActiveTab(value)
    setSearchParams({ tournament: value })
  }

  const sortedTournaments = useMemo(() => (
    sortBy(tournaments, ['startsAt', 'name'])
  ), [tournaments])

  const userLanguages = () => {
    if (navigator.languages != undefined) {
      return navigator.languages
    }
    return [navigator.language]
  }

  const localizedDescription = (tournament) => {
    let localizedDescription = tournament.description

    userLanguages().every((locale) => {
      if (locale == 'en') {
        return false
      } else if (tournament.localizedDescriptions[locale] != undefined) {
        localizedDescription = tournament.localizedDescriptions[locale]
        return false
      }

      return true
    })

    return localizedDescription
  }

  useEffect(() => {
    const initialTab = searchParams.get('tournament')
    const tabExists = tournaments.some((tournament) => tournament.id === initialTab)

    if (tabExists) {
      setActiveTab(initialTab)
    } else if (tournaments.length > 0) {
      setActiveTab(sortedTournaments[0].id)
    }
  }, [])

  useEffect(() => {
    if (slug !== event.slug) {
      navigate(`/events/${event.slug}/bracket${search}`, { replace: true })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', function(e) {
      if (e.data.ChallongeJSAPIEvent) {
        if (e.data.ChallongeJSAPIEvent === 'bracketMounted') {
          // whenever a new bracket is mounted, ask the iframe for its height
          iframeRef.current.contentWindow.postMessage({ ChallongeJSAPIAction: "broadcastHeight", parameters: {} }, "*")
        } else if (e.data.ChallongeJSAPIEvent === 'bracketHeightChanged') {
          // when the iframe's content height changes, adjust its height to prevent scrollbars
          if (e.data.parameters.pageHeight > MIN_IFRAME_HEIGHT) {
            setIframeHeight(e.data.parameters.pageHeight)
          }
        }
      }
    })

    setInterval(function() {
      iframeRef.current.contentWindow.postMessage({ ChallongeJSAPIAction: "broadcastHeight", parameters: {} }, "*")
    }, 1000)
  }, [iframeRef])

  return (
    <SimpleTabs>
      <TabGroup
        activeTab={activeTab}
        onChange={handleTabChange}
      >
        {sortedTournaments.map((tournament, idx) =>
          <Tab key={idx} label={tournament.name} value={tournament.id} />
        )}
      </TabGroup>
      <TabContent>
        {sortedTournaments.map((tournament, idx) =>
          <Fragment key={idx}>
            <TabPane
              activeTab={activeTab}
              value={tournament.id}
            >
              <SectionBlock extraClass='mt-5 mb-4'>
                <SectionHeading variant='main'>
                  <p className="head">About</p>
                </SectionHeading>
                <SectionContent>
                  <Collapse initialHeight={250}>
                    <Markdown text={localizedDescription(tournament)} />
                  </Collapse>
                </SectionContent>
              </SectionBlock>
            </TabPane>
            <TabPane
              activeTab={activeTab}
              value={tournament.id}
              hideOnly
            >
              <SectionBlock extraClass='mb-4'>
                <SectionHeading variant='side' color='white'>
                  <p className="head">{tournament.name}</p>
                </SectionHeading>
                <SectionContent>
                  <iframe
                    ref={iframeRef}
                    src={`${tournament.fullChallongeUrl}/module?theme=7984&show_final_results=1&final_results_showmore=1&multiplier=1.2&twitch_rivals=1`}
                    width='100%'
                    height={iframeHeight}
                    frameBorder='0'
                    scrolling='auto'
                    allowtransparency='true'
                  />
                </SectionContent>
              </SectionBlock>
            </TabPane>
          </Fragment>
        )}
      </TabContent>
    </SimpleTabs>
  )
}

export default Bracket
