import React from 'react'

import ConditionalWrapper from '@components/ConditionalWrapper'
import Spinner from './Spinner'

const OverlayLoader = ({ children, isLoading }) => {
  return (
    <ConditionalWrapper
      condition={isLoading}
      wrapper={(children) => <div className='loader-overlay'>{children}</div>}
    >
      {isLoading && <Spinner extraClass='-overlay' />}
      {children}
    </ConditionalWrapper>
  )
}

export default OverlayLoader
