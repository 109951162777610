import * as Yup from 'yup'

const ApplicationFormSchema = (fields) => {
  const validShape = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.name]: Yup.string().required('Required')
    }
  }, {})

  return Yup.object().shape(validShape)
}

export { ApplicationFormSchema }
