import React, { useMemo } from 'react'
import combineClass from '@helpers/combineClass'

const Tab = ({ activeTab, onClick, value, label, extraClass = '' }) => {
  const isActive = useMemo(() => {
    if (value === 0 || value) {
      return value === activeTab
    } else {
      return label === activeTab
    }
  }, [value, label, activeTab])

  const handleClick = () => {
    if (value === 0 || value) {
      onClick(value)
    } else {
      onClick(label)
    }
  }

  return (
    <div
      className={combineClass(
        'tab -btn',
        isActive ? '-active' : '',
        extraClass
      )}
      onClick={handleClick}
    >
      {label}
    </div>
  )
}

export default Tab
