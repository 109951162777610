import React from 'react'
import combineClass from '@helpers/combineClass'

const SectionBlock = ({ extraClass = '', children }) => {
  return (
    <div className={combineClass('section-block', extraClass)}>{children}</div>
  )
}

export default SectionBlock
