import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { sortBy } from 'lodash'

import {
  SectionBlock,
  SectionContent,
  SectionHeading
} from '@components/SectionBlock'
import { TournamentBlock } from '@components/TournamentBlock'
import { MetaItem } from '@components/MetaItem'
import { Markdown } from '@components/Markdown'
import Loader, { useLoader } from '@components/Loader'
import { FilterableTwitchUserList } from '@components/FilterableTwitchUserList'
import { parseJSON, isPast } from 'date-fns'


const Overview = ({ renderPlayerSpotlight = false, event }) => {
  const [confirmedParticipants, setConfirmedParticipants] = useState([])
  const { tournaments = [], description = '', localizedDescriptions = [] } = event
  const { slug } = useParams()
  const navigate = useNavigate()
  const { isLoading, startLoading, stopLoading } = useLoader()
  const eventIsPast = isPast(parseJSON(event.endsAt))

  let localizedDescription = description

  const userLanguages = () => {
    if (navigator.languages != undefined) {
      return navigator.languages
    }
    return [navigator.language]
  }

  // find the description to use based on the user's browser language preferences
  userLanguages().every((locale) => {
    if (locale == 'en') {
      return false
    } else if (localizedDescriptions[locale] != undefined) {
      localizedDescription = localizedDescriptions[locale]
      return false
    }

    return true
  })

  const sortedTournaments = useMemo(() => (
    sortBy(tournaments, ['startsAt', 'name'])
  ), [tournaments])

  useEffect(() => {
    if (slug !== event.slug) {
      navigate(`/events/${event.slug}`, { replace: true })
    } else {
      startLoading()

      fetch(`/events/${event.slug}/confirmed_participants.json`)
        .then((resp) => {
          if (resp.ok) {
            return resp.json()
          }

          throw new Error('Network Error')
        })
        .then((data) => {
          setConfirmedParticipants(data)
          stopLoading()
        })
        .catch((error) => {
          console.log(error)
          stopLoading()
        })
    }
  }, [])

  return (
    <div className='event-overview-layout'>
      <div className='about'>
	      { renderPlayerSpotlight ? (
          <div className='participants'>
            <SectionBlock extraClass='mb-10'>
              <SectionHeading variant='main'>
                <p className="head">{eventIsPast ? "Who Participated" : "Who's Participating"}</p>
              </SectionHeading>
              <SectionContent>
                <Loader isLoading={isLoading}>
                  {confirmedParticipants.length > 0 ? (
                    <FilterableTwitchUserList confirmedParticipants={confirmedParticipants} />
                  ) : (
                    <div>Check back soon to see who's confirmed for the show.<br /><br /></div>
                  )}
                </Loader>
              </SectionContent>
            </SectionBlock>
          </div>
	      ) : null }
        <SectionBlock>
          <SectionHeading variant='main'>
            <p className="head">About</p>
          </SectionHeading>
          <SectionContent>
            <Markdown text={localizedDescription} />
          </SectionContent>
        </SectionBlock>
      </div>
      <div className='tournament'>
        {sortedTournaments.length > 0 && (
          <SectionBlock extraClass='mb-5'>
            <SectionHeading variant='side'>
              <p className="head">Competitions</p>
            </SectionHeading>
            <SectionContent>
              {sortedTournaments.map((tournament, idx) =>
                <TournamentBlock
                  key={idx}
                  {...tournament}
                />
              )}
            </SectionContent>
          </SectionBlock>
        )}
        <SectionBlock>
          <SectionHeading variant='side'>
            <p className='head'>Contact</p>
          </SectionHeading>
          <SectionContent>
            <MetaItem
              iconColor='purple'
              size='lg'
              iconType='mail'
              textColor='white'
              text='twitchrivalsfeedback@twitch.tv'
            />
          </SectionContent>
        </SectionBlock>
      </div>
    </div>
  )
}

export default Overview
