import { useReducer } from 'react'
import flashMessageReducer from '../reducers/flashMessageReducer'

const useFlashMessage = () => {
  const [state, dispatch] = useReducer(flashMessageReducer, { isOpen: false })

  const closeFlashMessage = () => {
    dispatch({ type: 'CLOSE' })
  }

  const openFlashMessage = (status, message) => {
    dispatch({ type: 'OPEN', payload: { status, message } })
  }

  return {
    openFlashMessage,
    closeFlashMessage,
    flashMessageState: state
  }
}

export default useFlashMessage
