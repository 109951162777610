import SimpleTabs from './SimpleTabs'
import Tab from './Tab'
import TabContent from './TabContent'
import TabGroup from './TabGroup'
import TabPane from './TabPane'

export default SimpleTabs
export {
  Tab,
  TabContent,
  TabGroup,
  TabPane
}
