import React from 'react'
import combineClass from '@helpers/combineClass'

const TabPane = ({ activeTab, value, children, hideOnly = false }) => {
  if (hideOnly) {
    return (
      <div className={combineClass('tab-pane', activeTab === value ? '-active' : '')}>
        {children}
      </div>
    )
  } else {
    return activeTab === value ? children : null
  }
}

export default TabPane
