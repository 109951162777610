import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '@components/Modal'
import useModal from '@components/Modal/hooks/useModal'
import Button from '@components/Button'

import EventInfo from '@components/EventInfo'
import EventApplicationForm from '@components/EventApplicationForm'
import EventTermsConditionsForm from '@components/EventTermsConditionsForm'

const EventInvitation = ({
  id,
  status,
  showPrizing,
  showTermsAndConditionsUrl,
  event,
  data,
  onFormSubmitSuccess
}) => {
  const { modalState, openModal, closeModal } = useModal()
  const [btnContent, variant = ''] = useMemo(() => {
    switch (status) {
      case 'invited':
        return ['Accept Invitation', 'primary']
      case 'applied':
        return ['Under Review', 'primary-outline']
      case 'accepted':
        return ['View & Accept Terms', 'primary-outline']
      case 'confirmed':
        return [`You're In!`, 'success-outline']
      case 'denied':
        return ['Application Not Approved', 'error-outline']
      default:
        return []
    }
  }, [status])

  const handleButtonClick = useCallback(() => {
    switch (status) {
      case 'invited':
        openModal(
          <EventApplicationForm
            id={id}
            event={event}
            forCharity={showPrizing === 'Charity'}
            onSubmitSuccess={onFormSubmitSuccess}
          />
        )
        break
      case 'accepted':
        openModal(
          <EventTermsConditionsForm
            id={id}
            event={event}
            onSubmitSuccess={onFormSubmitSuccess}
            termsAndConditionsUrl={showTermsAndConditionsUrl}
          />
        )
        break
      default:
        openModal(
          <EventApplicationForm id={id} event={event} values={data} disabled />
        )
        break
    }
  }, [id, status, event])

  return (
    <>
      <div className='event-invitation'>
        <EventInfo {...event} />
        <div className='actions'>
          {btnContent && (
            <Button
              variant={variant}
              size='sm'
              disabled={status === 'denied'}
              type='button'
              onClick={handleButtonClick}
            >
              {btnContent}
            </Button>
          )}
        </div>
        <Modal {...modalState} onClose={closeModal} />
      </div>
    </>
  )
}

EventInvitation.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  data: PropTypes.object,
  event: PropTypes.object
}

export default EventInvitation
