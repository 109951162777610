import React, { useState, useEffect } from 'react'
import { parseJSON } from 'date-fns'
import { isDateRangeToday } from '@helpers/datetime-helper'

import FilterableEventList from '@components/FilterableEventList'
import Loader, { useLoader } from '@components/Loader'


const EventListContainer = () => {
  const [events, setEvents] = useState([])
  const { isLoading, startLoading, stopLoading } = useLoader()
  const transformEvents = (events) => {
    return events.map(({id, attributes}) => {
      const startsAt = parseJSON(attributes.startsAt)
      const endsAt = parseJSON(attributes.endsAt)
      const isToday = isDateRangeToday(startsAt, endsAt)

      return {
        ...attributes,
        id,
        startsAt,
        endsAt,
        isToday,
        url: window.location.origin + '/events/' + attributes.slug
      }
    })
  }

  useEffect(() => {
    startLoading()

    fetch('/events.json')
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }

        throw new Error('Network Error')
      })
      .then((data) => {
        setEvents(transformEvents(data))
        stopLoading()
      })
      .catch((error) => {
        console.error(error)
        stopLoading()
      })
  }, [])

  return (
    <Loader isLoading={isLoading} type='overlay'>
      <FilterableEventList events={events} isLoading={isLoading}/>
    </Loader>
  )
}

export default EventListContainer
