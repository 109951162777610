import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isPast, isWithinInterval } from 'date-fns'

import EventInfo from '@components/EventInfo'
import Button from '@components/Button'
import combineClass from '@helpers/combineClass'

const EventBlock = ({
  name,
  startsAt,
  endsAt,
  avatarUrl,
  bannerUrl,
  location,
  url,
  extraClass = ''
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleWatchNowClick = useCallback(() => {
    window.open('https://twitch.tv/twitchrivals')
  }, [])

  const handleCopyLinkClick = useCallback(() => {
    copyToClipboard(url)
    setIsCopied(true)

    const timer = setTimeout(() => {
      setIsCopied(false)
    }, 1000)

    setTimeoutId(timer)
  }, [url])

  const isLive = isWithinInterval(new Date(), { start: startsAt, end: endsAt })

  useEffect(() => {
    if (timeoutId) {
      return () => clearTimeout(timeoutId)
    }
  }, [timeoutId])

  return (
    <div className={combineClass('event-block', extraClass)}>
      <EventInfo
        url={url}
        avatarUrl={avatarUrl}
        name={name}
        startsAt={startsAt}
        endsAt={endsAt}
        location={location}
      >
        {isLive ? (
          <span className='tag -live'>
            <strong>LIVE</strong>
          </span>
        ) : isPast(endsAt) && (
          <span className='tag -ended'>
            <strong>SHOW ENDED</strong>
          </span>
        )}
      </EventInfo>
      <div className='actions'>
        {isLive && (
          <Button variant='secondary' onClick={handleWatchNowClick}>
            Watch Now
          </Button>
        )}
        <Button variant='secondary' onClick={handleCopyLinkClick}>
          {isCopied ? 'Copied' : 'Copy Link'}
        </Button>
      </div>
      {isLive && <img src={bannerUrl} alt='banner' className='banner' />}
    </div>
  )
}

EventBlock.propTypes = {
  name: PropTypes.string.isRequired,
  startsAt: PropTypes.instanceOf(Date),
  endsAt: PropTypes.instanceOf(Date),
  avatarUrl: PropTypes.string,
  bannerUrl: PropTypes.string,
  location: PropTypes.string,
  url: PropTypes.string,
}

export default EventBlock
