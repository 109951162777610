import React from 'react'

const SimpleTabs = ({children}) => {
  return (
    <div className='nav-tabs'>
      {children}
    </div>
  )
}

export default SimpleTabs
