import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import axios from '@clients/axios'

import SearchBar from '@components/SearchBar'

const UserSearch = () => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)

  const transformData = ({ data }) => {
    return data.map(({ attributes: { username, profileImage }, links }) => {
      return {
        username: username,
        image: profileImage,
        url: links.self
      }
    })
  }

  const fetchSearchResults = async (queryString) => {
    try {
      const response = await axios.get('/players', {
        params: { q: queryString }
      })

      const { data } = response
      setResults(transformData(data))
      setShowResults(true)
    } catch (error) {
      console.error(error)
    }
  }

  const debouncedFetchSearchResults = useDebouncedCallback(fetchSearchResults, 800)

  const handleChange = (e) => {
    setQuery(e.target.value)
    debouncedFetchSearchResults.cancel()
    handleSearch(e.target.value.trim())
  }

  const handleSearch = (queryString) => {
    setResults([])
    setShowResults(false)

    if (queryString.length > 0) {
      debouncedFetchSearchResults(queryString)
    }
  }

  const handleSubmit = () => {
    handleSearch(query)
  }

  const handleClear = () => {
    setQuery('')
    setResults([])
    setShowResults(false)
  }

  return (
    <SearchBar
      value={query}
      results={results}
      showResults={showResults}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClear={handleClear}
    />
  )
}

export default UserSearch
