import React from 'react'

import TextField from '@components/TextField'
import DisplayedData from './DisplayedData'

const ToggleableField = ({
  label,
  value,
  onChange,
  placeholder = '',
  helpText = '',
  isEditing
}) => {
  if (isEditing) {
    return (
      <TextField
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        helpText={helpText}
      />
    )
  } else {
    return (
      <DisplayedData
        label={label}
        value={value}
      />
    )
  }
}

export default ToggleableField
