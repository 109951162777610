import React from 'react'
import { Outlet } from 'react-router-dom'

import ExpressInterestButton from '@components/ExpressInterestButton'
import CustomLink from './CustomLink'

const NavLayout = ({
  event,
  expressInterestState = "hidden",
  sections = [],
  hasFinalResults = false,
}) => {
  return (
    <>
      <div className='flex-elem -between -aligncenter'>
        <div className='nav-tabs'>
          <div className='tab-groups'>
            <CustomLink to={`/events/${event.slug}${hasFinalResults ? '/overview' : ''}`}>Overview</CustomLink>
            { sections.includes('rules') &&
              <CustomLink to={`/events/${event.slug}/rules`}>Rules</CustomLink>
            }
            { event.tournaments.length > 0 &&
              <CustomLink to={`/events/${event.slug}/bracket`}>Bracket{event.tournaments.length > 1 && 's'}</CustomLink>
            }
            { hasFinalResults &&
              <CustomLink to={`/events/${event.slug}`}>Results</CustomLink>
            }
            { sections.includes('private_player_section') &&
              <CustomLink to={`/events/${event.slug}/private_player_section`}>
                <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" style={{marginRight: "5px", verticalAlign: "-2px"}}>
                  <path d="M74,45V28.5C74,16.093,63.907,6,51.5,6h-3C36.093,6,26,16.093,26,28.5V33h9v-4.5  C35,21.056,41.056,15,48.5,15h3C58.944,15,65,21.056,65,28.5V45H26c-4.971,0-9,4.029-9,9v30c0,4.971,4.029,9,9,9h48  c4.971,0,9-4.029,9-9V54C83,49.029,78.971,45,74,45z M53,71.166V78c0,1.657-1.343,3-3,3s-3-1.343-3-3v-6.834  c-1.785-1.041-3-2.954-3-5.166c0-3.309,2.691-6,6-6s6,2.691,6,6C56,68.211,54.785,70.125,53,71.166z" fill="#ffffff"/>
                </svg>
                Know Before You Stream
              </CustomLink>
            }
          </div>
        </div>
        <div className='actions'>
          <ExpressInterestButton
            expressInterestState={expressInterestState}
            eventSlug={event.slug}
          />
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default NavLayout
