import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import combineClass from '@helpers/combineClass'

import ClockIcon from '@images/icons/clock-icon.svg'
import CalendarIcon from '@images/icons/calendar-icon.svg'
import LocationIcon from '@images/icons/location-icon.svg'
import TrophyIcon from '@images/icons/trophy-icon.svg'
import GameIcon from '@images/icons/game-icon.svg'
import MailIcon from '@images/icons/mail-icon.svg'

const MetaItem = ({ iconType, iconColor, label, text, size, textColor }) => {
  const iconImg = useMemo(() => {
    switch (iconType) {
      case 'calendar':
        return {
          url: CalendarIcon + `#icon-${iconType}`,
          variant: '-calendar'
        }
      case 'clock':
        return {
          url: ClockIcon + `#icon-${iconType}`,
          variant: '-clock'
        }
      case 'location':
        return {
          url: LocationIcon + `#icon-${iconType}`,
          variant: '-location'
        }
      case 'trophy':
        return {
          url: TrophyIcon + `#icon-${iconType}`,
          variant: '-trophy'
        }
      case 'game':
        return {
          url: GameIcon + `#icon-${iconType}`,
          variant: '-game'
        }
      case 'mail':
        return {
          url: MailIcon + `#icon-${iconType}`,
          variant: '-mail'
        }
      default:
        return ''
    }
  }, [iconType])

  const iconColorClass = useMemo(() => {
    switch (iconColor) {
      case 'purple':
        return `-${iconColor}`
      default:
        return ''
    }
  })

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'lg':
        return `-${size}`
      default:
        return ''
    }
  })

  const textColorClass = useMemo(() => {
    switch (textColor) {
      case 'white':
        return `-${textColor}`
      default:
        return ''
    }
  }, [textColor])

  return (
    <span className={combineClass('meta-item', sizeClass)}>
      <svg
        className={combineClass(
          'icon react-svg-icon',
          iconImg.variant,
          iconColorClass
        )}
      >
        <use href={iconImg.url} />
      </svg>
      <div className='info'>
        {label && <label className='lbl'>{label}</label>}
        <p className={combineClass('text', textColorClass)}>{text}</p>
      </div>
    </span>
  )
}

MetaItem.propTypes = {
  iconType: PropTypes.oneOf([
    'calendar',
    'clock',
    'location',
    'trophy',
    'game',
    'mail'
  ]),
  iconColor: PropTypes.oneOf(['default', 'purple']),
  textColor: PropTypes.oneOf(['default', 'white']),
  size: PropTypes.oneOf(['default', 'lg']),
  text: PropTypes.string
}

export default MetaItem
