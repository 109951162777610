import React from 'react'
import PropTypes from 'prop-types'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const Markdown = ({ text }) => {
  return (
    <ReactMarkdown
      className='react-markdown'
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    >
      {text}
    </ReactMarkdown>
  )
}

Markdown.propTypes = {
  text: PropTypes.string
}

export default Markdown
