import React from 'react'

const DisplayedData = ({ label, value }) => (
  <div className='flex-elem -column mb-5'>
    <p className='m-0 mb-2'>
      <strong>{label}</strong>
    </p>
    <p className='chl-text -primary m-0'>{value}</p>
  </div>
)

export default DisplayedData
