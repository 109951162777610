import React from 'react'

const SearchField = ({
  autoFocus = true,
  onClear,
  onOpen,
  isExpanded,
  ...props
}) => {
  return (
    <div className='search-form'>
      <button onClick={onOpen} className='btn-icon'>
        <svg className='icon svg-icon -default' viewBox='0 0 24 24'>
          <use xlinkHref='#icon-search' />
        </svg>
      </button>
      {isExpanded && (
        <>
          <input type='text' {...props} autoFocus={autoFocus} className='field' />
          <button onClick={onClear} className='btn-icon'>
            <svg className='icon svg-icon -default' viewBox='0 0 25 25'>
              <use xlinkHref='#icon-close' />
            </svg>
          </button>
        </>
      )}
    </div>
  )
}

export default SearchField
