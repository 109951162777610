import React, { useMemo } from 'react'
import combineClass from '@helpers/combineClass'

const CardHeader = ({ type = 'h1', children, className = '' }) => {
  const Tag = useMemo(
    () => (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(type) ? type : 'h4'),
    [type]
  )

  return <Tag className={combineClass('header', className)}>{children}</Tag>
}

export default CardHeader
