import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compareAsc, format, isToday, isSameDay } from 'date-fns'
import { getDayName } from '@helpers/datetime-helper'

import { SectionHeader } from '@components/SectionHeader'
import { EventBlock } from '@components/EventBlock'

const EventList = ({events, sortDir = 'asc'}) => {
  const sortedEvents = useMemo(() => {
    const sortValue = sortDir === 'asc' ? 1 : -1
    return events.sort((a, b) => {
      return compareAsc(a.startsAt, b.startsAt) * sortValue
    })
  }, [events, sortDir])

  const eventBlocksWithSections = useMemo(() => {
    const { elements } = sortedEvents.reduce(({currentDate, elements }, event) => {
      const eventBlock = (
        <EventBlock
          {...event}
          key={'event-' + event.id}
          extraClass='mb-3'
        />
      )

      if (!!currentDate && (event.isToday && isToday(currentDate) || isSameDay(currentDate, event.startsAt))) {
        return {
          currentDate,
          elements: [...elements, eventBlock]
        }
      } else {
        const newCurrentDate = event.isToday ? new Date() : event.startsAt
        const headerDate = event.isToday ? newCurrentDate : event.startsAt

        const sectionHeader = (
          <SectionHeader key={'section-' + event.startsAt} extraClass='mt-5 mb-3'>
            <span>{format(headerDate, 'MMMM d, yyyy')}</span>
            <span>{event.isToday ? 'Today' : getDayName(event.startsAt)}</span>
          </SectionHeader>
        )

        return {
          currentDate: newCurrentDate,
          elements: [...elements, sectionHeader, eventBlock]
        }
      }
    }, { currentDate: null, elements: [] })

    return elements
  }, [sortedEvents])

  return (
    <div className='events-list'>
      { eventBlocksWithSections }
    </div>
  )
}

EventList.propTypes = {
  events: PropTypes.array,
  sortDir: PropTypes.oneOf(['asc', 'desc'])
}

export default EventList
