import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import combineClass from '@helpers/combineClass'

const MetaList = ({ children, type = '' }) => {
  const typeClass = useMemo(() => {
    switch (type) {
      case 'horizontal':
      case 'two-columns':
        return `-${type}`
      default:
        return ''
    }
  }, [type])

  return <ul className={combineClass('meta-list', typeClass)}>{children}</ul>
}

MetaList.propTypes = {
  type: PropTypes.string
}

export default MetaList
