import React, { useState, useEffect, useRef } from 'react'

import Grid from '@components/Grid'
import TwitchUserCard from '@components/TwitchUserCard'

const spacer = "..."

const FilterableTwitchUserList = ({
  confirmedParticipants = []
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [page, setPage] = useState(1)
  const lowercaseSearchValue = (searchValue || "").toLowerCase()
  const filteredParticipants = confirmedParticipants.filter((p) => {
    return p.players.find((p) => p.display_name.toLowerCase().includes(lowercaseSearchValue)) !== undefined
  })
  const teamSize = Math.max(...confirmedParticipants.map((p) => p.players.length))
  let perPage = 2 * Math.round(12 / teamSize / 2)
  if (perPage < 2) {
    perPage = 2
  }
  const totalParticipants = filteredParticipants.length
  const totalPages = Math.ceil(totalParticipants / perPage)
  const filteredParticipantsPage = filteredParticipants.slice((page - 1) * perPage, page * perPage)

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    const listTop = document.getElementById("filtered-twitch-users")
    if (listTop) {
      const topOffset = listTop.offsetTop
      window.scrollTo({ top: topOffset - 140, behavior: "smooth" })
    }
  }, [page])

  return (
    <>
      <div className="vertical-field" id="filtered-twitch-users">
        <input
          className="form-control mb-5"
          type="search"
          placeholder="Find your favorite creator"
          value={searchValue}
          onChange={(e) => {
            setPage(1)
            setSearchValue(e.target.value)
          }}
        />
      </div>
      <Grid column={2} className="-with-row-gap">
        {
          filteredParticipantsPage.map((participant, i) => (
            teamSize === 1 ? (
              participant.players.map((player) => (
                <TwitchUserCard
                  key={player.channel_id}
                  displayName={player.display_name}
                  profileImageUrl={player.profile_image_url}
                />
              ))
            ) : (
              <div key={participant.team}>
                <div style={{color: "#000000", fontSize: "16px", fontWeight: "700", backgroundColor: "#9146FF", padding: "16px"}}>
                  {participant.team}
                </div>
                <div style={{backgroundColor: "#180E25", paddingTop: "5px"}}>
                  {participant.players.map((player) => (
                    <TwitchUserCard
                      key={player.channel_id}
                      displayName={player.display_name}
                      profileImageUrl={player.profile_image_url}
                    />
                  ))}
                </div>
              </div>
            )
          ))
        }
      </Grid>

      <div className="pagination">
        <div className="showing-summary">
          {totalParticipants === 0 ? (
            <div>
              No matches found.
            </div>
          ) : (
            <div>
              Showing {(page - 1) * perPage + 1}-{Math.min(page * perPage, totalParticipants)} of {totalParticipants} {teamSize === 1 ? 'participants' : 'teams'}
            </div>
          )}
        </div>

        { totalPages > 1 && totalPages <= 5 && (
          Array.apply(1, Array(totalPages)).map(function (x, index) {
            const iteratedPage = index + 1
            return (
              <div className="page-link" key={iteratedPage}>
                { iteratedPage === page ?
                  <span className="current-page">{iteratedPage}</span>
                  : <a href="javascript:void(0)" onClick={() => setPage(iteratedPage)} key={index}>{iteratedPage}</a> }
              </div>
            )
          })
        )}

        { totalPages > 5 && (
          <div className="page-links">
            { page > 1 && (
              <div className="page-link -auto-width">
                <a href="javascript:void(0)" onClick={() => setPage(page - 1)}>
                  Previous
                </a>
              </div>
            )}

            { page > 2 && (
              <>
                <div className="page-link">
                  <a href="javascript:void(0)" onClick={() => setPage(1)}>
                    {1}
                  </a>
                </div>
                <div className="page-link">
                  <span>...</span>
                </div>
              </>
            )}

            { page - 1 > 0 && (
              <div className="page-link">
                <a href="javascript:void(0)" onClick={() => setPage(page - 1)}>
                  {page - 1}
                </a>
              </div>
            )}

            <div className="page-link">
              <span className="current-page">{page}</span>
            </div>

            { page < totalPages && (
              <div className="page-link">
                <a href="javascript:void(0)" onClick={() => setPage(page + 1)}>
                  {page + 1}
                </a>
              </div>
            )}

            { page + 1 < totalPages && (
              <>
                <div className="page-link">
                  <span>...</span>
                </div>
                <div className="page-link">
                  <a href="javascript:void(0)" onClick={() => setPage(totalPages)}>
                    {totalPages}
                  </a>
                </div>
              </>
            )}

            { page < totalPages && (
              <div className="page-link -auto-width">
                <a href="javascript:void(0)" onClick={() => setPage(page + 1)}>
                  Next
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default FilterableTwitchUserList
