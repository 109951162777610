import React from 'react'
import CoStreamingBanner from '@images/costreaming/banner.png'
import CoStreamingExtra from '@images/costreaming/costreaming.png'

const CoStreaming = () => {
  return (
    <div className='costreaming-block'>
      <img className='banner' src={CoStreamingBanner} />
      <div className='details'>
        <p>
          Twitch Rivals is multiplayer competitive entertainment - and the best part? It's all available for co-streaming!
          <br/>
          <br/>
          Co-streaming is a great way to connect with your community around Twitch Rivals competition and create content different from what you normally stream. When you co-stream, you can put your unique spin on our broadcast and add whatever you would like to make the experience special for your audience.
          <br/>
          <br/>
          All Twitch Rivals events are co-streamable and we encourage creator participation from any language.
        </p>
        <img src={CoStreamingExtra}/>
      </div>
      <a className='link' href='https://rivals.twitch.tv/assets/twitch-rivals-co-streamer-kit.zip'>
        Get our Co-Streaming Kit
        <svg className='icon svg-icon -purple -lg' viewBox='0 0 15 15'>
          <use href='#icon-redirect'/>
        </svg>
      </a>
    </div>
  )
}

export default CoStreaming
