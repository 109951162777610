import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import combineClass from '@helpers/combineClass'

const SectionHeader = ({ children, type = 'h4', extraClass = '' }) => {
  const Tag = useMemo(() => (
      ['h1', 'h2', 'h3', 'h4', 'h5'].includes(type) ? type : 'h4'
  ), [type])

  return (
    <Tag className={combineClass('section-header', extraClass)}>
      {children}
    </Tag>
  )
}

SectionHeader.propTypes = {
  type: PropTypes.string,
  extraClass: PropTypes.string
}

export default SectionHeader
