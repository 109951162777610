import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import combineClass from '@helpers/combineClass'

const Button = ({
  onClick,
  children,
  variant = 'default',
  size = '',
  extraClass = '',
  disabled = false,
  type = 'button'
}) => {
  const variantClass = useMemo(() => {
    switch (variant) {
      case 'primary':
      case 'secondary':
      case 'primary-outline':
      case 'success-outline':
      case 'error-outline':
        return `btn-${variant}`
      default:
        return ''
    }
  }, [variant])

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'lg':
      case 'sm':
        return `btn-${size}`
      default:
        return ''
    }
  }, [size])

  return (
    <button
      className={combineClass('btn', variantClass, sizeClass, extraClass)}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  extraClass: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-outline',
    'success-outline',
    'error-outline'
  ]),
  size: PropTypes.oneOf(['lg', 'sm']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string
}

export default Button
