import React, { Children, cloneElement } from 'react'

const TabContent = ({activeTab, children}) => {
  const childrenWithProps = () => {
    return Children.map(children, (child, idx) => {
      return cloneElement(child, {
        key: idx,
        activeTab
      })
    })
  }

  return (
    <div className='tab-content'>
      { childrenWithProps() }
    </div>
  )
}

export default TabContent
