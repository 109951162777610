import { useState, useEffect, useRef } from 'react'

const useCollapse = (initialVal, initialHeight = 0) => {
  const [collapseState, setCollapseState] = useState(initialVal)
  const [collapseHeight, setCollapseHeight] = useState(initialHeight)
  const collapseRef = useRef(null)

  const setCollapse = (val) => {
    setCollapseState(val)
  }

  const handlers = {
    expand: () => setCollapse(true),
    collapse: () => setCollapse(false),
    toggle: () => setCollapse(!collapseState)
  }

  useEffect(() => {
    setCollapseHeight(collapseRef.current?.scrollHeight)
  }, [collapseRef.current])

  return {
    collapseState,
    collapseHeight,
    handlers,
    collapseRef
  }
}

export default useCollapse
