import React, { useState, useEffect } from 'react'

import axios from '@clients/axios'

import AnchorLink from '@components/AnchorLink'
import Grid from '@components/Grid'
import Card, { CardHeader, CardContent } from '@components/Card'
import Loader, { useLoader } from '@components/Loader'
import UserEventList from '../UserEventList/UserEventList'
import { parseJSON, isFuture } from 'date-fns'
import { isDateRangeToday } from '@helpers/datetime-helper'
import {
  SectionBlock,
  SectionHeading,
} from '@components/SectionBlock'
import GoldMedal from '@images/icons/icon-medal-first.svg'
import SilverMedal from '@images/icons/icon-medal-second.svg'
import BronzeMedal from '@images/icons/icon-medal-third.svg'

const UserProfile = ({ user, events }) => {
  const [description, setDescription] = useState('')
  const [followerCount, setFollowerCount] = useState('')
  const { isLoading, startLoading, stopLoading } = useLoader()

  const transformEvents = (events) => {
    return events.map(({id, attributes}) => {
      const startsAt = parseJSON(attributes.startsAt)
      const endsAt = parseJSON(attributes.endsAt)
      const isToday = isDateRangeToday(startsAt, endsAt)

      return {
        ...attributes,
        id,
        startsAt,
        endsAt,
        isToday,
        url: window.location.origin + '/events/' + attributes.slug
      }
    })
  }

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`/players/${user.username}/details`)
      const { data } = response

      stopLoading()
      setDescription(data.description)
      setFollowerCount(data.followerCount)
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  const transformedEvents = transformEvents(events)
  const upcomingEvents = transformedEvents.filter(event => event.isToday || isFuture(event.startsAt))
  const pastEvents = transformedEvents.filter(event => !event.isToday && !isFuture(event.startsAt))
  const goldMedals = transformedEvents.filter(event => event.placement === '1st')
  const silverMedals = transformedEvents.filter(event => event.placement === '2nd')
  const bronzeMedals = transformedEvents.filter(event => event.placement === '3rd')
  const eventsEntered = transformedEvents.length
  const totalMedals = goldMedals.length + silverMedals.length + bronzeMedals.length

  useEffect(() => {
    startLoading()
    fetchUserDetails()
  }, [])

  return (
    <Loader isLoading={isLoading} type='overlay'>
      <div className='flex-elem -between -phwrap -aligncenter mb-8'>
        <div className='flex-elem'>
          <img src={user.profileImage} className='avatar-image -s64 -nds64' />
          <div className='ml-3 mr-2'>
            <h4 className='m-0 mb-1'>
              <AnchorLink href={`https://twitch.tv/${user.username}`} className='-purple chl-text -wbreak'>
                {user.displayName}
                <svg className='icon svg-icon -stroke -purple -sm ml-1' viewBox='1 0 14 14'>
                  <use xlinkHref='#icon-redirect' />
                </svg>
              </AnchorLink>
            </h4>
            {!isLoading && (
              <p className='m-0'>{followerCount} followers</p>
            )}
          </div>
        </div>
        <div className='flex-elem -flex-a0'>
          <AnchorLink href={'https://twitch.tv/' + user.username} className='btn btn-primary btn-sm btn-block'>
            Follow on Twitch
          </AnchorLink>
        </div>
      </div>
      {!isLoading && (
        <>
          <SectionBlock extraClass='mb-3'>
            <Card>
              <CardHeader type='h4'>Twitch Bio</CardHeader>
              <CardContent>
                {description}
              </CardContent>
            </Card>
          </SectionBlock>
          <SectionBlock extraClass='text-center'>
            <Grid column={totalMedals > 0 ? 3 : 2}>
              <Card>
                <CardHeader type='h1'>
                  {goldMedals.length}
                </CardHeader>
                <CardContent className='-gray'>
                  Twitch Rivals Events Won
                </CardContent>
              </Card>
              {totalMedals > 0 && (
                <Card>
                  <div style={{marginBottom: "12px"}}>
                    {goldMedals.map(event => (
                      <a href={`#event-${event.id}`}>
                        <img src={GoldMedal} height="55" style={{margin: "0 2px"}} />
                      </a>
                    ))}
                    {silverMedals.map(event => (
                      <a href={`#event-${event.id}`}>
                        <img src={SilverMedal} height="55" style={{margin: "0 2px"}} />
                      </a>
                    ))}
                    {bronzeMedals.map(event => (
                      <a href={`#event-${event.id}`}>
                        <img src={BronzeMedal} height="55" style={{margin: "0 2px"}} />
                      </a>
                    ))}
                  </div>
                  <CardContent className='-gray'>
                    Twitch Rivals Podium Finishes
                  </CardContent>
                </Card>
              )}
              <Card>
                <CardHeader type='h1'>{eventsEntered}</CardHeader>
                <CardContent className='-gray'>
                  Twitch Rivals Events Entered
                </CardContent>
              </Card>
            </Grid>
          </SectionBlock>
        </>
      )}

      {upcomingEvents.length > 0 && (
        <SectionBlock>
          <SectionHeading variant='main' color='white' extraClass='mt-7'>
            <p className='head'>Upcoming Events</p>
          </SectionHeading>
          <UserEventList events={upcomingEvents} sortDir="asc" />
        </SectionBlock>
      )}

      {pastEvents.length > 0 && (
        <SectionBlock>
          <SectionHeading variant='main' color='white' extraClass='mt-7'>
            <p className='head'>Past Events</p>
          </SectionHeading>
          <UserEventList events={pastEvents} sortDir="desc" />
        </SectionBlock>
      )}
    </Loader>
  )
}

export default UserProfile
