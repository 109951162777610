import React from 'react'
import PropTypes from 'prop-types'

import combineClass from '@helpers/combineClass'

const Checkbox = ({
  onClick = () => {},
  value,
  className = '',
  children,
  label,
  id,
  ...props
}) => {
  const handleClick = (e) => {
    onClick(e.target.checked)
  }

  return (
    <label id={id} className={combineClass('checkbox-control', className)}>
      <input
        className='field'
        onClick={handleClick}
        type='checkbox'
        value={value}
        {...props}
      />
      {children || <span className='text'>{label}</span>}
    </label>
  )
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string
}

export default Checkbox
