const ConditionalWrapper = ({condition, wrapper, elseWrapper = null, children}) => {
  if (condition) {
    return wrapper(children)
  } else if (elseWrapper) {
    return elseWrapper(children)
  } else {
    return children
  }
}

export default ConditionalWrapper
