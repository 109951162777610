import React from 'react'

const SearchResults = ({ results = [] }) => {
  return (
    <div className='search-dropdown'>
      <h5 className='chl-text -white-gray m-0 mb-3'>Search Results</h5>
      {results.length > 0 ? (
        <ul className='search-list'>
          {results.map((item, idx) => (
            <li key={idx}>
              <a
                href={item.url}
                className='flex-elem -aligncenter'
              >
                <img src={item.image} className='avatar-image -s40' />
                <span className='ml-2'> {item.username} </span>
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <i className='chl-text -white-gray'> No results found </i>
      )}
    </div>
  )
}

export default SearchResults
