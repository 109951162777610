import React from 'react'
import PropTypes from 'prop-types'

import { MetaItem } from '@components/MetaItem'
import { getDateRange, getTimeWithZone } from '@helpers/datetime-helper'
import combineClass from '@helpers/combineClass'

const EventInfo = ({
  name,
  url,
  avatarUrl,
  startsAt,
  endsAt,
  location,
  children,
  extraClass = ''
}) => {
  return (
    <div className={combineClass('event-info', extraClass)}>
      <a href={url} className='cover'>
        <img className='cover' src={avatarUrl} alt='' />
      </a>
      <div className='details'>
        {children}
        <a href={url} className='title'>
          {name}
        </a>
        <ul className='meta-list'>
          <MetaItem
            iconType='calendar'
            text={getDateRange(startsAt, endsAt)}
          />
          <MetaItem iconType='clock' text={getTimeWithZone(startsAt)} />
          {location && <MetaItem iconType='location' text={location} />}
        </ul>
      </div>
    </div>
  )
}

EventInfo.propTypes = {
  url: PropTypes.string,
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  startsAt: PropTypes.instanceOf(Date),
  endsAt: PropTypes.instanceOf(Date),
  location: PropTypes.string,
  extraClass: PropTypes.string
}

export default EventInfo
