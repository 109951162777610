import axios from 'axios'

const axiosClient = axios.create()

const csrfNode = document.querySelector("[name='csrf-token']")
const csrf = csrfNode ? csrfNode.getAttribute('content') : ''

axiosClient.defaults.baseURL = window.location.origin

axiosClient.defaults.headers = {
  'X-CSRF-Token': csrf,
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export default axiosClient
