import React from 'react'

import SimpleTabs, { Tab, TabGroup } from '@components/SimpleTabs'
import { BlankSlate } from '@components/BlankSlate'
import { EventInvitation } from '@components/EventInvitation'

const FilterableEventInvitationList = ({
  activeTab,
  eventInvitations = [],
  onTabChange,
  onFormSubmitSuccess
}) => {
  return (
    <>
      <SimpleTabs>
        <TabGroup activeTab={activeTab} onChange={onTabChange}>
          <Tab extraClass='-sm' value='all' label='ALL' />
          <Tab extraClass='-sm' value='invited' label='NEW' />
          <Tab extraClass='-sm' value='applied' label='UNDER REVIEW' />
          <Tab extraClass='-sm' value='accepted' label='AWAITING YOUR TERMS ACCEPTANCE' />
          <Tab extraClass='-sm' value='confirmed' label='PARTICIPATING' />
          <Tab extraClass='-sm' value='denied' label='NOT PARTICIPATING' />
        </TabGroup>
      </SimpleTabs>
      {eventInvitations.length > 0 ? (
        eventInvitations.map((eventInvitation) => (
          <EventInvitation
            {...eventInvitation}
            key={eventInvitation.id}
            onFormSubmitSuccess={onFormSubmitSuccess}
          />
        ))
      ) : (
        <BlankSlate text='No shows available' />
      )}
    </>
  )
}

export default FilterableEventInvitationList
