import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import combineClass from '@helpers/combineClass'
import CloseIcon from '@images/icons/close-icon.svg'

const FlashMessage = ({ isOpen = false, status, message, onClose }) => {
  const portalNode = document.getElementById('react-portal-flash-message')
  const variantClass = useMemo(() => {
    switch (status) {
      case 'success':
      case 'error':
        return `-${status}`
      default:
        return ''
    }
  }, [status])

  if (!isOpen) return null
  return ReactDOM.createPortal(
    <div className={combineClass('flash-message', variantClass)}>
      <button className='close' type='button' onClick={onClose}>
        <svg className='icon react-svg-icon -white'>
          <use href={CloseIcon + `#icon-close`} />
        </svg>
      </button>
      {message}
    </div>,
    portalNode
  )
}

FlashMessage.propTypes = {
  status: PropTypes.oneOf(['success', 'error']),
  message: PropTypes.string,
  onClose: PropTypes.func
}

export default FlashMessage
