import React from 'react'
import PropTypes from 'prop-types'

import Button from '@components/Button'
import { useCollapse } from '@hooks'
import combineClass from '@helpers/combineClass'

const Collapse = ({ children, initialVal = false, initialHeight = 0 }) => {
  const { collapseState, handlers, collapseHeight, collapseRef } = useCollapse(
    initialVal,
    initialHeight
  )

  return (
    <>
      <div
        className={combineClass(
          'collapse-container',
          collapseHeight > initialHeight
            ? collapseState
              ? '-less'
              : ''
            : '-less'
        )}
        style={{ maxHeight: collapseState ? 99999 : initialHeight }}
        ref={collapseRef}
      >
        {children}
      </div>
      {collapseHeight > initialHeight && (
        <center className={collapseState ? 'mt-2' : ''}>
          <Button onClick={handlers.toggle} variant='secondary'>
            See {collapseState ? 'less' : 'more'}
          </Button>
        </center>
      )}
    </>
  )
}

Collapse.propTypse = {
  initialVal: PropTypes.bool,
  initialHeight: PropTypes.number
}

export default Collapse
