import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import combineClass from '@helpers/combineClass'

const TextField = ({
  label = '',
  onChange,
  value,
  placeholder,
  disabled = false,
  helpText = '',
  inputClass = '',
  extraClass = '',
  validationInfo = '',
  ...props
}) => {
  const inputVariant = useMemo(() => {
    switch (inputClass) {
      case 'success':
      case 'error':
        return `-${inputClass}`
      default:
        return ''
    }
  })

  return (
    <div className={combineClass('vertical-field', extraClass)}>
      {label && <label>{label}</label>}
      {helpText && <p className='help-text'>{helpText}</p>}
      <input
        type='text'
        className={combineClass('form-control', inputVariant)}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
      {validationInfo && (
        <p className={combineClass('validation', inputVariant)}>
          {validationInfo}
        </p>
      )}
    </div>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  extraClass: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  validationInfo: PropTypes.string
}

export default TextField
