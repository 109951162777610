import React from 'react'

import OverlayLoader from './OverlayLoader'
import SpinnerLoader from './SpinnerLoader'

const Loader = ({ type = 'spinner', ...props }) => {
  if (type === 'overlay') {
    return <OverlayLoader {...props} />
  } else {
    return <SpinnerLoader {...props} />
  }
}

export default Loader
