import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { titleize } from '@helpers/string-helper'
import { MetaList } from '@components/MetaList'
import { MetaItem } from '@components/MetaItem'

const TournamentBlock = ({
  id,
  name = '',
  gameName = '',
  tournamentType = '',
  startsAt,
  imageUrl
}) => {
  const metaItems = useMemo(() => {
    const defaultItems = [
      { icon: 'game', text: gameName },
      { icon: 'trophy', text: titleize(tournamentType) }
    ]

    if (startsAt) {
      return [
        ...defaultItems,
        { icon: 'calendar', text: format(startsAt, 'MMMM d, yyyy') },
        { icon: 'clock', text: format(startsAt, 'h:mm aaa zzz') }
      ]
    } else {
      return defaultItems
    }
  }, [startsAt])

  return (
    <div className='tournament-block'>
      {imageUrl && (
        <Link to={`bracket?tournament=${id}`}>
          <img className='image' src={imageUrl} />
        </Link>
      )}
      <div className='details'>
        <p className='name'>
          <Link to={`bracket?tournament=${id}`}>{name}</Link>
        </p>
        <MetaList type='two-columns'>
          {metaItems.map((i, index) => (
            <li key={index}>
              <MetaItem iconType={i.icon} text={i.text} />
            </li>
          ))}
        </MetaList>
      </div>
    </div>
  )
}

TournamentBlock.propTypes = {
  name: PropTypes.string,
  startsAt: PropTypes.instanceOf(Date),
  tournamentType: PropTypes.string,
  gameName: PropTypes.string
}

export default TournamentBlock
