import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import axios from '@clients/axios'

import Button from '@components/Button'
import Grid from '@components/Grid'
import Loader, { useLoader } from '@components/Loader'

import ToggleableField from './ToggleableField'

const UserSettings = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [settings, setSettings] = useState({})
  const { isLoading, startLoading, stopLoading } = useLoader()

  const handleSubmit = async (values) => {
    try {
      const response = await axios.put('/settings', {
        data: {
          discord_id: values.discordId,
          epic_id: values.epicId,
          riot_id: values.riotId,
          steam_64_id: values.steam64Id,
          email: values.email,
          new_viewer_pitch: values.newViewerPitch,
          something_special_or_interesting: values.somethingSpecialOrInteresting,
          breakout_moment: values.breakoutMoment,
          proud_achievement: values.proudAchievement,
          loved_sleeper_game: values.lovedSleeperGame,
          unpopular_opinion: values.unpopularOpinion,
          desert_island_game: values.desertIslandGame,
          twitch_global_emote: values.twitchGlobalEmote,
        }
      })
      const { data } = response

      stopLoading()
      setSettings(data)
      setIsEditing(false)
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: settings,
    onSubmit: (values) => {
      startLoading()
      handleSubmit(values)
    }
  })

  const handleCancel = () => {
    formik.resetForm()
    setIsEditing(false)
  }

  const fetchSettings = async () => {
    try {
      const response = await axios.get('/settings')

      const { data } = response

      stopLoading()
      setSettings(data)
    } catch (error) {
      stopLoading()
      console.error(error)
    }
  }

  useEffect(() => {
    startLoading()
    fetchSettings()
  }, [])

  return (
    <Loader isLoading={isLoading} type='overlay'>
      <form onSubmit={formik.handleSubmit}>
        <div className='flex-elem -between -aligncenter -phwrap mb-10'>
          <h1 className='m-0'>Account</h1>
          <div className='flex-elem -center -phwrap -gap-2'>
            {isEditing ? (
              <>
                <Button
                  variant='secondary'
                  size='sm'
                  onClick={handleCancel}
                  extraClass='-phsecond'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='primary'
                  size='sm'
                >
                  Save Changes
                </Button>
              </>
            ) : (
              <Button
                variant='primary'
                size='sm'
                onClick={() => setIsEditing(true)}
              >
                Edit Information
              </Button>
            )}
          </div>
        </div>
        <Grid column={2}>
          <div className='flex-elem -column'>
            <ToggleableField
              label='Discord ID'
              placeholder='Discord ID (Example: discordname#0001)'
              helpText='Example: discordname#0001'
              value={formik.values.discordId}
              onChange={(e) => formik.setFieldValue('discordId', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='32-character Epic ID'
              placeholder='32-character Epic ID (Example: e37eb71e488d4362921b8484ae2b0032)'
              helpText='Example: e37eb71e488d4362921b8484ae2b0032'
              value={formik.values.epicId}
              onChange={(e) => formik.setFieldValue('epicId', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='Riot ID'
              placeholder='Riot ID (Example: riotname #ABC)'
              helpText='Example: riotname #ABC'
              value={formik.values.riotId}
              onChange={(e) => formik.setFieldValue('riotId', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='17-character Steam64 ID'
              placeholder='Steam64 ID (Example: 76561197960287930)'
              helpText='Example: 76561197960287930'
              value={formik.values.steam64Id}
              onChange={(e) => formik.setFieldValue('steam64Id', e.target.value)}
              isEditing={isEditing}
            />
          </div>
          <div className='flex-elem -column'>
            <ToggleableField
              label='Email Address for Twitch Rivals'
              placeholder='Email Address for Twitch Rivals'
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              isEditing={isEditing}
            />
          </div>
        </Grid>

        <div className='section-block mb-5'>
          <div className='heading -main -white mt-10 mb-10'>
            <div className='head'>Your 'Broadcast Hype' Questions</div>
            <div className='sub'>
              <p>These questions will be used to promote and hype you up on during a Twitch Rivals broadcast by our team! Fill out as many or as few as you want.</p>
            </div>
          </div>
          <div className='content'>
            <ToggleableField
              label='What is your pitch to new viewers? How would you describe your content?'
              value={formik.values.newViewerPitch}
              onChange={(e) => formik.setFieldValue('newViewerPitch', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='What is special or interesting about you that the audience might not know?'
              value={formik.values.somethingSpecialOrInteresting}
              onChange={(e) => formik.setFieldValue('somethingSpecialOrInteresting', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='What was your biggest breakout moment as a streamer that people know you for?'
              value={formik.values.breakoutMoment}
              onChange={(e) => formik.setFieldValue('breakoutMoment', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='What achievement are you most proud of as a streamer or gamer?'
              value={formik.values.proudAchievement}
              onChange={(e) => formik.setFieldValue('proudAchievement', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label="What's the sleeper game you've always loved?"
              value={formik.values.lovedSleeperGame}
              onChange={(e) => formik.setFieldValue('lovedSleeperGame', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='What is an unpopular opinion you have in gaming?'
              value={formik.values.unpopularOpinion}
              onChange={(e) => formik.setFieldValue('unpopularOpinion', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='If you were stuck on a desert island and you could bring only one game to stream until you were rescued, what would you choose?'
              value={formik.values.desertIslandGame}
              onChange={(e) => formik.setFieldValue('desertIslandGame', e.target.value)}
              isEditing={isEditing}
            />
            <ToggleableField
              label='What Twitch Global Emote describes you the best?'
              value={formik.values.twitchGlobalEmote}
              onChange={(e) => formik.setFieldValue('twitchGlobalEmote', e.target.value)}
              isEditing={isEditing}
            />
          </div>
        </div>
        <div className='flex-elem -between -aligncenter -phwrap mb-10'>
          {isEditing ? (
            <>
              <Button
                variant='secondary'
                size='sm'
                onClick={handleCancel}
                extraClass='-phsecond'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='primary'
                size='sm'
              >
                Save Changes
              </Button>
            </>
          ) : (
            <Button
              variant='primary'
              size='sm'
              onClick={() => setIsEditing(true)}
            >
              Edit Information
            </Button>
          )}
        </div>
      </form>
    </Loader>
  )
}

export default UserSettings
