import React from 'react'
import PropTypes from 'prop-types'

import AnchorLink from '../AnchorLink'

const ProfileBanner = ({ username, displayName, twitchAvatar }) => {
  return (
    <div className='profile-banner'>
      <div className='avatar'>
        <img src={twitchAvatar} alt='Twitch Logo' />
      </div>
      <div className='details'>
        <h1 className='heading'>{displayName}</h1>
        <h5 className='subheading'>
          <AnchorLink href={`https://twitch.tv/${username}`}>
            {`twitch.tv/${username} `}
            <svg className='icon svg-icon -stroke -sm' viewBox='1 0 14 14'>
              <use xlinkHref='#icon-redirect' />
            </svg>
          </AnchorLink>
        </h5>
      </div>
    </div>
  )
}

ProfileBanner.propTypes = {
  displayName: PropTypes.string,
  username: PropTypes.string,
  twitchAvatar: PropTypes.string
}

export default ProfileBanner
