import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compareAsc, format, isToday, isSameMonth } from 'date-fns'

import { SectionHeader } from '@components/SectionHeader'
import { UserEventBlock } from '@components/UserEventBlock'

const UserEventList = ({events, sortDir = 'asc'}) => {
  const sortedEvents = useMemo(() => {
    const sortValue = sortDir === 'asc' ? 1 : -1
    return events.sort((a, b) => {
      return compareAsc(a.startsAt, b.startsAt) * sortValue
    })
  }, [events, sortDir])

  const eventBlocksWithSections = useMemo(() => {
    const { elements } = sortedEvents.reduce(({currentDate, elements }, event) => {
      const eventBlock = (
        <UserEventBlock
          {...event}
          key={'event-' + event.id}
          extraClass='mb-3'
        />
      )

      if (!!currentDate && (event.isToday && isToday(currentDate) || isSameMonth(currentDate, event.startsAt))) {
        return {
          currentDate,
          elements: [...elements, eventBlock]
        }
      } else {
        const newCurrentDate = event.isToday ? new Date() : event.startsAt
        const headerDate = event.isToday ? newCurrentDate : event.startsAt

        const sectionHeader = (
          <div id={`event-${event.id}`}>
            <SectionHeader key={'section-' + event.startsAt} extraClass='mt-5 mb-3'>
              <span>{format(headerDate, 'MMMM yyyy')}</span>
            </SectionHeader>
          </div>
        )

        return {
          currentDate: newCurrentDate,
          elements: [...elements, sectionHeader, eventBlock]
        }
      }
    }, { currentDate: null, elements: [] })

    return elements
  }, [sortedEvents])

  return (
    <div className='events-list'>
      { eventBlocksWithSections }
    </div>
  )
}

UserEventList.propTypes = {
  events: PropTypes.array,
  sortDir: PropTypes.oneOf(['asc', 'desc'])
}

export default UserEventList
